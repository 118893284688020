import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Download as DownloadType } from '../../../types/lp'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as DownloadStyles from '../../../styles/pages/lp/Download.css'
import { motion, useAnimation } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faChevronDown } from '@fortawesome/pro-regular-svg-icons'

// ref: https://developers.google.com/recaptcha/docs/v3#site_verify_response
interface SiteVerifyResponse {
  success: boolean
  score: number
  action: string
  challenge_ts: string
  hostname: string
  'error-codes'?: string[]
}

const verifyBot = async (token: string) => {
  const endpoint = 'https://plaid.co.jp/api/re-captcha/'

  const result: SiteVerifyResponse = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  }).then((response) => response.json())

  return { isBot: result.score < 0.8, errorCodes: result?.['error-codes'] }
}

interface FormEntity {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
  utm_term: string
  visitor_id: string
  lastName: string
  firstName: string
  company: string
  houjinbangou: string
  email: string
  phone: number
  subscribe: boolean
  inquire: string
  agree: boolean
  aim: string[]
  businessModel: string[]
  department: string
  jobTitle: string
}

const Download = ({ data, slug }: { data: DownloadType['data']; slug: string }) => {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm<FormEntity>()

  const onWatchJobTitle = watch('jobTitle')

  const [isSubmitted, setSubmitted] = useState<boolean>(false)
  const [isReCaptchaError, setReCaptchaError] = useState<boolean>(false)
  const [isModalOpen, setModalOpenState] = useState(false)

  const ebookForm = useRef<HTMLFormElement>(null)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async (formData: FormEntity) => {
    setSubmitted(true)

    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available')
      setReCaptchaError(true)
      setSubmitted(false)

      return
    }

    const token = await executeRecaptcha(`blockskarteio/ebook/${slug}/`)
    const { isBot, errorCodes } = await verifyBot(token)

    if (errorCodes) {
      errorCodes.forEach((error) => console.error(error))
      setReCaptchaError(true)
      setSubmitted(false)

      return
    }

    if (isBot) {
      console.error('Bot detected. Please try again.')

      return
    }

    const { email } = formData;
    const isInvalidEmail = /@appier.com|@kaizenplatform.com|@repro.io|@leanplum.com|@satori.team|@salesforce.com|@adobe.com|@f-scratch.com|@sprocket.bz|@synergy101.jp|@brainpad.co.jp|@zendesk.com|@intercom.co.jp|@treasure-data.com|@hicustomer.jp|@cloudcircus.co.jp|@techtouch.co.jp|@appcues.com|@pendo.io|@contentsquare.com|@ptmind.com|@email.braze.com|@braze.com|@hubspot.com|@genne.jp|@useinsider.com|@jamuinc.com|@medallia.com|@rokt.com|@so-tech.co.jp|@tealium.com|@twilio.com|@zetacx.com|@emberpoint.com|@kairosmarketing.net|@qualtrics.com|@startiaholdings.com|@startia.co.jp|@supreme-system.com|@cheetahdigital.com|@tricorn.co.jp|@domo.com|@richrelevance.jp|@oracle.com|@e-grant.net|@yumemi.co.jp|@f-code.co.jp|@cresclab.com|@connecty.co.jp|@sitepublis.co.jp|@scinable.com|@shanon.co.jp|@j-tamas.com|@bebit.com|@benchmarkemail.com|@unison-planet.com|@sub-fac.com|@glad-cube.com|@ecbeing.co.jp/i.test(email)
    if(isInvalidEmail) {
      setModalOpenState(true)
      console.error('Invalid email address')
      return
    }

    if (!ebookForm.current) return

    ebookForm.current.action = data.downloadFormUrl
    ebookForm.current.submit()
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://ichisan.jp/form/lib/ichisanForm.min.js';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://ichisan.jp/form/lib/ichisanForm.min.css';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  
   // Framer Motion Modal
   const controls = useAnimation()

   const variants = {
     close: {
       display: 'none',
       transition: { duration: 0.2, ease: [0.19, 1.0, 0.22, 1.0] },
     },
     open: {
       display: 'block',
       transition: { duration: 0.3, ease: [0.895, 0.03, 0.685, 0.22] },
     },
   }
   
   useEffect(() => {
     if (isModalOpen) controls.start('open')
     else controls.start('close')
   }, [isModalOpen])
   
   const [houjinbangouValue, setHoujinbangouValue] = useState('')
 
   const onChangeCompany = useCallback(() => {
     const houjinbangou = document.querySelector('.company_id')?.getAttribute('value')
     houjinbangou && setValue('houjinbangou', houjinbangou)
     setHoujinbangouValue(houjinbangou || '')
   }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
  }, [])

  return (
    <>
    <motion.div initial="close" animate={controls} variants={variants} aria-label="Competitive-Domain-Exclusion-Modal">
     <div
       onClick={() => setModalOpenState(false)}
       className={DownloadStyles.Modal_Overlay}
     />
     <div className={DownloadStyles.Modal_Content}>  
       <p className={DownloadStyles.Modal_text}>
         大変恐れ入りますが、弊社と事業領域の重なる企業様からの資料請求、お客様窓口からのお問い合わせはご遠慮いただいております。
         <br />
         弊社へのご連絡・お問い合わせに関しましては、
         <a href="https://plaid.co.jp/contact/" target="_blank" rel="noopener" className={DownloadStyles.Modal_link}>
           こちら
         </a>
         &nbsp;よりお願いいたします。
       </p>
       <p className={DownloadStyles.Modal_text}>何卒ご了承いただきますようお願い申し上げます。</p>
       <button
         onClick={() => setModalOpenState(false)} className={DownloadStyles.Modal_Close_button}
       >
         <FontAwesomeIcon icon={faXmark} width="28" />
       </button>
     </div>
   </motion.div>

    <section id="download" className={DownloadStyles.Container}>
      <div className={DownloadStyles.Inner}>
        <h2 className={DownloadStyles.Heading}>資料ダウンロード</h2>
        <p className={DownloadStyles.Lead}>
          ご登録のメールアドレスに
          <br className={SiteWideStyles.DisplayMobileOnly} />
          資料ダウンロードURLをお送りいたします。
        </p>
        <div className={DownloadStyles.Division}>
          <img src={data.downloadImage.src} alt="" width="327" height="184" loading="lazy" className={DownloadStyles.Image} />
          <div className={DownloadStyles.Summary}>
            <p>
              {data.downloadText.split('\n').map((line: string, index: number) => (
                <React.Fragment key={index}>
                  {line}
                  <br className={SiteWideStyles.DisplayDesktopOnly} />
                </React.Fragment>
              ))}
            </p>
            <ol className={DownloadStyles.Outline}>
              {data.downloadNumberList?.map((item, index) => (
                <li className={DownloadStyles.Item} key={`outline_${index}`}>
                  {item.numberListText}
                </li>
              ))}
            </ol>
          </div>
          <form method="post" ref={ebookForm} onSubmit={handleSubmit(onSubmit)} className={DownloadStyles.Form}>
            <div className={DownloadStyles.Form_Inner}>
              <input type="hidden" value="" {...register('utm_source')} />
              <input type="hidden" value="" {...register('utm_medium')} />
              <input type="hidden" value="" {...register('utm_campaign')} />
              <input type="hidden" value="" {...register('utm_content')} />
              <input type="hidden" value="" {...register('utm_term')} />
              <input type="hidden" value="" {...register('visitor_id')} />
              <input type="hidden" value={houjinbangouValue} className="company_id" {...register('houjinbangou')} />

              {isReCaptchaError && (
                <div className={DownloadStyles.Form_Item}>
                  <p className={DownloadStyles.Form_ErrorText}>エラーが発生しました。お手数ですが、時間をおいてから再度お試しください</p>
                </div>
              )}

              <p className={DownloadStyles.Note} data-classname="EnterpriseForm_Note">
                <a href="https://partner.karte.io/contact" className={DownloadStyles.Blacktext} target="_blank" rel="noopener">
                  代理店、パートナー企業の方でクライアントへのご提案を検討されている方は
                  <span className={DownloadStyles.BlackTextLink}>こちら</span>
                  からお問い合わせください。
                </a>
              </p>

              <div className={DownloadStyles.Form_Item}>
                <label htmlFor="lastName" className={DownloadStyles.Form_RequiredLabel}>
                  名前
                </label>
                <div className={DownloadStyles.Form_NarrowInput}>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="姓"
                    autoComplete="family-name"
                    {...register('lastName', {
                      required: '姓を入力してください',
                    })}
                    className={classNames(DownloadStyles.Form_Field, errors.lastName && 'is-error')}
                  />
                  <input
                    type="text"
                    id="firstName"
                    placeholder="名"
                    autoComplete="given-name"
                    {...register('firstName', {
                      required: '名を入力してください',
                    })}
                    className={classNames(DownloadStyles.Form_Field, errors.lastName && 'is-error')}
                  />
                  {errors.lastName && <p className={DownloadStyles.Form_ErrorText}>{errors.lastName.message}</p>}
                  {errors.firstName && <p className={DownloadStyles.Form_ErrorText}>{errors.firstName.message}</p>}
                </div>
              </div>

              <div className={DownloadStyles.Form_Item}>
                <label htmlFor="company" className={DownloadStyles.Form_RequiredLabel}>
                  会社名
                </label>
                <div className={DownloadStyles.Form_Input}>
                  <input
                    type="text"
                    id="company"
                    placeholder="株式会社プレイド"
                    autoComplete="organization"
                    {...register('company', {
                      required: '会社名を入力してください',
                      onBlur: onChangeCompany
                    })}
                    className={classNames('company_name', DownloadStyles.Form_Field, errors.company && 'is-error')}
                  />
                  {errors.company && <p className={DownloadStyles.Form_ErrorText}>{errors.company.message}</p>}
                </div>
              </div>

              <div className={DownloadStyles.Form_Item}>
                <p className={DownloadStyles.Form_RequiredLabel}>導入検討先</p>
                <div className={classNames(DownloadStyles.Form_ItemCheckBox, 'is-checkBox', errors.businessModel ? 'is-error' : '')}>
                  <div>
                    <input
                      value="自社"
                      type="radio"
                      id="myself"
                      className={classNames(DownloadStyles.Form_Origin)}
                      {...register('businessModel', {
                        required: '導入検討先をいずれか選択してください',
                      })}
                    />
                    <label htmlFor="myself" className={classNames(DownloadStyles.Form_Checkbox)}>
                    自社
                    </label>
                  </div>
                  <div>
                    <input
                      value="クライアントに提案する"
                      type="radio"
                      id="forClient"
                      className={classNames(DownloadStyles.Form_Origin)}
                      {...register('businessModel')}
                    />
                    <label htmlFor="forClient" className={classNames(DownloadStyles.Form_Checkbox)}>
                    クライアントに提案する
                    </label>
                  </div>

                  {errors.businessModel && <p className={DownloadStyles.Form_ErrorText}>{errors.businessModel.message}</p>}
                </div>
              </div>

              <div className={DownloadStyles.Form_Item}>
                <label htmlFor="department" className={classNames(DownloadStyles.Form_RequiredLabel)}>
                  部署
                </label>
                <div className={DownloadStyles.Form_Input}>
                  <input
                    type="text"
                    id="department"
                    placeholder="マーケティング部"
                    autoComplete="organization"
                    {...register('department', {
                      required: '部署を入力してください',
                    })}
                    className={classNames(DownloadStyles.Form_Field, errors.department && 'is-error')}
                  />
                  {errors.department && <p className={DownloadStyles.Form_ErrorText}>{errors.department.message}</p>}
                </div>
              </div>

              <div className={DownloadStyles.Form_Item}>
                <label htmlFor="jobTitle" className={DownloadStyles.Form_RequiredLabel}>
                  役職
                </label>
                <div className={classNames('Input',DownloadStyles.Cascader, !onWatchJobTitle && DownloadStyles.Cascader_Unselected)}>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    width="10"
                    className={DownloadStyles.Cascader_Icon}
                    data-classname="EnterpriseForm_Cascader_Icon"
                  />
                  <select
                    id="jobTitle"
                    {...register('jobTitle', { required: '役職を選択してください' })}
                    className={classNames(DownloadStyles.Form_Field, !onWatchJobTitle && DownloadStyles.Form_Unselected, errors.jobTitle && 'is-error')}
                  >
                    <option value="" hidden>
                      選択してください
                    </option>
                    <option value="取締役">取締役</option>
                    <option value="執行役員・本部長">執行役員・本部長</option>
                    <option value="部長">部長</option>
                    <option value="課長">課長</option>
                    <option value="一般社員">一般社員</option>
                    <option value="どれも当てはまらない">どれも当てはまらない</option>
                  </select>
                  {errors.jobTitle && <p className={DownloadStyles.Form_ErrorText}>{errors.jobTitle.message}</p>}
                </div>
              </div>

              <div className={DownloadStyles.Form_Item}>
                <label htmlFor="email" className={DownloadStyles.Form_RequiredLabel}>
                  法人メールアドレス
                </label>
                <div className={DownloadStyles.Form_Input}>
                  <input
                    type="text"
                    id="email"
                    placeholder="tanaka@plaid.co.jp"
                    autoComplete="username"
                    {...register('email', {
                      required: '法人メールアドレスを入力してください',
                      pattern: {
                        value: /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
                        message: 'メールアドレスの形式に誤りがあります',
                      },
                    })}
                    className={classNames(DownloadStyles.Form_Field, errors.email && 'is-error')}
                  />
                  <p className={DownloadStyles.Form_Notice}>※法人メールアドレスをご入力ください</p>
                  {errors.email && <p className={DownloadStyles.Form_ErrorText}>{errors.email.message}</p>}
                </div>
              </div>

              <div className={DownloadStyles.Form_Item}>
                <label htmlFor="phone" className={DownloadStyles.Form_RequiredLabel}>
                  電話番号
                </label>
                <div className={DownloadStyles.Form_Input}>
                  <input
                    type="tel"
                    id="phone"
                    placeholder="08012345678"
                    {...register('phone', {
                      required: '電話番号を入力してください',
                      pattern: {
                        value: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,5}$/,
                        message: '数字のみ入力してください',
                      },
                      minLength: {
                        value: 10,
                        message: '電話番号の形式に誤りがあります',
                      },
                    })}
                    className={classNames(DownloadStyles.Form_Field, errors.phone && 'is-error')}
                  />
                  {errors.phone && <p className={DownloadStyles.Form_ErrorText}>{errors.phone.message}</p>}
                </div>
              </div>

              <div className={DownloadStyles.Form_Item}>
                <p className={DownloadStyles.Form_RequiredLabel}>資料請求の目的</p>
                <div className={classNames(DownloadStyles.Form_ItemCheckBox, 'is-checkBox', errors.aim ? 'is-error' : '')}>
                <div>
                    <input
                      value="特に具体的な課題はないが、情報収集をしている"
                      type="radio"
                      id="gathering"
                      className={classNames(DownloadStyles.Form_Origin)}
                      {...register('aim', {
                        required: '資料請求の目的をいずれか選択してください',
                      })}
                    />
                    <label htmlFor="gathering" className={classNames(DownloadStyles.Form_Checkbox)}>
                    具体的な課題はないが、情報収集をしている
                    </label>
                  </div>
                <div>
                    <input
                      value="具体的な課題があり、解決方法を探している"
                      type="radio"
                      id="searching"
                      className={classNames(DownloadStyles.Form_Origin)}
                      {...register('aim')}
                    />
                    <label htmlFor="searching" className={classNames(DownloadStyles.Form_Checkbox)}>
                    具体的な課題があり、解決方法を探している
                    </label>
                  </div>
                  <div>
                    <input
                      value="導入を検討している"
                      type="radio"
                      id="adopt"
                      className={classNames(DownloadStyles.Form_Origin)}
                      {...register('aim')}
                    />
                    <label htmlFor="adopt" className={classNames(DownloadStyles.Form_Checkbox)}>
                    導入を検討している
                    </label>
                  </div>
                  
                  <div>
                    <input
                      value="その他"
                      type="radio"
                      id="other"
                      className={classNames(DownloadStyles.Form_Origin)}
                      {...register('aim')}
                    />
                    <label htmlFor="other" className={classNames(DownloadStyles.Form_Checkbox)}>
                      その他
                    </label>
                  </div>

                  {errors.aim && <p className={DownloadStyles.Form_ErrorText}>{errors.aim.message}</p>}
                </div>
              </div>

              <div className={DownloadStyles.Form_Item}>
                <label htmlFor="inquire" className={DownloadStyles.Form_Label}>
                  ご質問・ご要望
                </label>
                <textarea name="inquire" id="inquire" {...register} className={DownloadStyles.Form_TextArea} />
              </div>

              <div className={DownloadStyles.Form_ItemInformation}>
                <span className={DownloadStyles.Form_RequiredLabel}>個人情報取り扱い</span>

                <div className={classNames(DownloadStyles.Form_Control, errors.agree ? 'is-error' : '')}>
                  <input
                    type="checkbox"
                    id="agree"
                    value="同意します"
                    {...register('agree', {
                      required: '個人情報取り扱いをご確認のうえ、✓を入れてください',
                    })}
                    className={classNames(DownloadStyles.Form_Origin)}
                  />
                  <label htmlFor="agree" className={classNames(DownloadStyles.Form_Checkbox)}>
                    <a
                      href="https://karte.io/legal/contact-legal.html"
                      target="_blank"
                      rel="noopener"
                      className={DownloadStyles.Form_TextLink}>
                      個人情報取り扱い
                    </a>{' '}
                    に同意します
                  </label>
                  {errors.agree && <p className={DownloadStyles.Form_ErrorText}>{errors.agree.message}</p>}
                </div>
              </div>
            </div>

            <button type="submit" disabled={isSubmitted || Object.keys(errors).length > 0} className={DownloadStyles.Form_SubmitButton}>
              送信する
            </button>

            <small className={DownloadStyles.Form_ReCaptcha}>
              This site is protected by reCAPTCHA and
              <br />
              the Google{' '}
              <a href="https://policies.google.com/privacy" className={DownloadStyles.Form_ReCaptchaLink}>
                Privacy Policy
              </a>{' '}
              and{' '}
              <a href="https://policies.google.com/terms" className={DownloadStyles.Form_ReCaptchaLink}>
                Terms of Service
              </a>{' '}
              apply.
            </small>
          </form>
        </div>
      </div>
      <div className={DownloadStyles.Background}>
        <picture>
          <source srcSet="/assets/images/lpo/download-background-pc.svg" width="1614" height="564" media="(min-width:1024px)" />
          <img src="/assets/images/lpo/download-background-sp.svg" width="537" height="148" alt="" />
        </picture>
      </div>
    </section>
    </>
  )
}

export default Download
