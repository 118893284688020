import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as CosmeProblemStyles from '../../../styles/pages/cosme/Problem.css'

const CosmeProblem = () => {
  const problem_list = [
    {
      image: (
        <StaticImage
          src="../../../images/cosme/ploblem_1.png"
          alt="「メッセージ・体験に一貫性がない」のイメージイラスト"
          width={372}
          height={234}
          className={classNames(CosmeProblemStyles.Catalog_Image, 'first')}
          data-classname="CosmeProblem-Catalog_Image"
        />
      ),
      headline: (
        <>
          メッセージ・体験に
          <br className={SiteWideStyles.DisplayDesktopOnly} />
          一貫性がない
        </>
      ),
      summary: (
        <>
          サイト外接触時の<span>クリエイティブやメッセージと異なる訴求がサイトに</span>。ユーザー視点で違和感、離脱に直結。
        </>
      ),
    },
    {
      image: (
        <StaticImage
          src="../../../images/cosme/ploblem_2.png"
          alt="「魅力を伝えきれていないわかりづらい」のイメージイラスト"
          width={234}
          height={243}
          className={classNames(CosmeProblemStyles.Catalog_Image, 'second')}
          data-classname="CosmeProblem-Catalog_Image"
        />
      ),
      headline: (
        <>
          魅力を伝えきれていない
          <br />
          わかりづらい
        </>
      ),
      summary: (
        <>
          コンテンツは所狭しと詰められているが、肝心のブランドとしての<span>コンセプトや強みをうまく訴求できていない</span>。
        </>
      ),
    },
    {
      image: (
        <StaticImage
          src="../../../images/cosme/ploblem_3.png"
          alt="「改善に向けたアイデアが乏しい」のイメージイラスト"
          width={279}
          height={234}
          className={classNames(CosmeProblemStyles.Catalog_Image, 'third')}
          data-classname="CosmeProblem-Catalog_Image"
        />
      ),
      headline: (
        <>
          改善に向けた
          <br className={SiteWideStyles.DisplayDesktopOnly} />
          アイデアが乏しい
        </>
      ),
      summary: (
        <>
          サイトにおける<span>顧客体験の理解が高まらず、仮説も持ちにくい</span>。 できる改善はやってきたはずで、頭打ち感。
        </>
      ),
    },
  ]
  return (
    <section className={CosmeProblemStyles.Container} data-classname="CosmeProblem-Container">
      <div className={CosmeProblemStyles.Inner} data-classname="CosmeProblem-Inner">
        <img
          src="/assets/images/cosme/ploblem_object_left.png"
          alt=""
          aria-hidden="true"
          width={200}
          height={169}
          className={classNames(SiteWideStyles.DisplayDesktopOnly, CosmeProblemStyles.Decoration, 'left')}
        />
        <img
          src="/assets/images/cosme/ploblem_object_right.png"
          alt=""
          aria-hidden="true"
          width={173}
          height={150}
          className={classNames(SiteWideStyles.DisplayDesktopOnly, CosmeProblemStyles.Decoration, 'right')}
        />
        <div className={CosmeProblemStyles.HeadlineOuter} data-classname="CosmeProblem-HeadlineOuter">
          <p className={CosmeProblemStyles.Eyebrow} data-classname="CosmeProblem-Eyebrow">
            ユーザーを魅了する
          </p>
          <h2 className={CosmeProblemStyles.Headline} data-classname="CosmeProblem-Headline">
            D2Cブランドの
            <br className={SiteWideStyles.DisplayMobileOnly} />
            サイト運営における課題
          </h2>
          <div className={CosmeProblemStyles.Lead} data-classname="CosmeProblem-Lead">
            <p className={CosmeProblemStyles.Lead_Text} data-classname="CosmeProblem-Lead_Text">
              数多くのD2Cブランドが立ち上がる一方、コロナ禍を経てデジタル広告出稿量が増え、<span>新規顧客の獲得はますます困難</span>に。
              <br className={SiteWideStyles.DisplayDesktopOnly} />
              だからこそ、<span>ブランドサイト流入後の顧客体験の重要性</span>が高まっています。
            </p>
            <p className={CosmeProblemStyles.Lead_Text} data-classname="CosmeProblem-Lead_Text">
              でも、継続的にサイト改善で成果を出していくことはそんなに簡単ではありません。
            </p>
          </div>
        </div>
        <div className={CosmeProblemStyles.Problem} data-classname="CosmeProblem-Problem">
          <div className={CosmeProblemStyles.Catalog} data-classname="CosmeProblem-Catalog">
            {problem_list.map((item, index) => (
              <div key={`problem_${index}`} className={CosmeProblemStyles.Catalog_Inner} data-classname="CosmeProblem-Catalog_Inner">
                <div className={CosmeProblemStyles.Catalog_Item} data-classname="CosmeProblem-Catalog_Item">
                  {item.image}
                  <div className={CosmeProblemStyles.Catalog_Content} data-classname="CosmeProblem-Catalog_Content">
                    <h3 className={CosmeProblemStyles.Catalog_Headline} data-classname="CosmeProblem-Catalog_Headline">
                      {item.headline}
                    </h3>
                    <p className={CosmeProblemStyles.Catalog_Summary} data-classname="CosmeProblem-Catalog_Summary">
                      {item.summary}
                    </p>
                  </div>
                </div>
                <div className={CosmeProblemStyles.Dot}>
                  <span className={CosmeProblemStyles.Dot_Item}></span>
                  <span className={classNames(CosmeProblemStyles.Dot_Item, 'second')}></span>
                  <span className={classNames(CosmeProblemStyles.Dot_Item, 'third')}></span>
                </div>
              </div>
            ))}
          </div>
          <div className={CosmeProblemStyles.Content} data-classname="CosmeProblem-Content">
            <h3 className={CosmeProblemStyles.Content_Headline} data-classname="CosmeProblem-Content_Headline">
              サイトを制作・改修する
              <br className={SiteWideStyles.DisplayMobileOnly} />
              ハードルが高い…
            </h3>
            <div className={CosmeProblemStyles.Content_Image} data-classname="CosmeProblem-Content_Image">
              <StaticImage
                src="../../../images/cosme/ploblem_summary_desktop.png"
                alt="「サイトを制作・改修するハードルが高い…」のイメージフロー図"
                className={SiteWideStyles.DisplayDesktopOnly}
              />
              <StaticImage
                src="../../../images/cosme/ploblem_summary_mobile.png"
                alt="「サイトを制作・改修するハードルが高い…」のイメージフロー図"
                className={SiteWideStyles.DisplayMobileOnly}
              />
            </div>
            <p className={classNames(SiteWideStyles.DisplayDesktopOnly, CosmeProblemStyles.Content_Summary)}>
              結果、実施できる改善施策も限定的で、一つ一つのリードタイムも長くなるから、
              <br />
              どうしてもサイト改善まで手を回せない。
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CosmeProblem
