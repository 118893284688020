import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as CosmeFeatureStyles from '../../../styles/pages/cosme/Feature.css'
import classNames from 'classnames'

const CosmeFeature = () => {
  const ites = [
    {
      text: (
        <>
          ビジュアル
          <br className={SiteWideStyles.DisplayDesktopOnly} />
          エディター
        </>
      ),
      icon: <StaticImage src="../../../images/cosme/feature01.png" alt="" width={73} height={60} />,
    },
    {
      text: (
        <>
          コード
          <br className={SiteWideStyles.DisplayDesktopOnly} />
          エディター
        </>
      ),
      icon: <StaticImage src="../../../images/cosme/feature02.png" alt="" width={80} height={65} />,
    },
    {
      text: 'テンプレート',
      icon: <StaticImage src="../../../images/cosme/feature03.png" alt="" width={121} height={53} />,
    },
    {
      text: 'A/Bテスト',
      icon: <StaticImage src="../../../images/cosme/feature04.png" alt="" width={100} height={100} />,
    },
    {
      text: (
        <>
          パーソナライズ
          <br className={SiteWideStyles.DisplayDesktopOnly} />
          配信
        </>
      ),
      icon: <StaticImage src="../../../images/cosme/feature05.png" alt="" width={128} height={90} />,
    },
    {
      text: '勝ちパターン判定',
      icon: <StaticImage src="../../../images/cosme/feature06.png" alt="" width={101} height={64} />,
    },
    {
      text: 'セグメント分析',
      icon: <StaticImage src="../../../images/cosme/feature07.png" alt="" width={81} height={63} />,
    },
    {
      text: 'ブロック効果分析',
      icon: <StaticImage src="../../../images/cosme/feature08.png" alt="" width={82} height={64} />,
    },
    {
      text: '複数ゴール登録',
      icon: <StaticImage src="../../../images/cosme/feature09.png" alt="" width={84} height={73} />,
    },
    {
      text: 'ライブ',
      icon: <StaticImage src="../../../images/cosme/feature10.png" alt="" width={82} height={58} />,
    },
  ]
  return (
    <section className={CosmeFeatureStyles.Container} data-classname="CosmeFeature-Container">
      <div className={CosmeFeatureStyles.Inner} data-classname="CosmeFeature-Inner">
        <h3 className={CosmeFeatureStyles.Headline} data-classname="CosmeFeature-Headline">
          顧客を理解し、スピーディーに、 <br className={CosmeFeatureStyles.Headline_Br} />
          サイト改善するための
          <br />
          <span>必要な機能</span>が揃っています
        </h3>
        <ul className={CosmeFeatureStyles.Catalog} data-classname="CosmeFeature-Catalog">
          {ites.map((item, index) => (
            <li key={`feature_${index}`}>
              <div className={CosmeFeatureStyles.ImageOuter} data-classname="CosmeFeature-ImageOuter">
                {item.icon}
              </div>
              <p className={CosmeFeatureStyles.Catalog_Headline} data-classname="CosmeFeature-Catalog_Headline">
                {item.text}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default CosmeFeature
