import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as CosmeGainStyles from '../../../styles/pages/cosme/Gain.css'
import CosmeDownloadButton from '../../../components/pages/cosme/DownloadButton'

const CosmeGain = () => {
  return (
    <section className={CosmeGainStyles.Container} data-classname="CosmeGain-Container">
      <div className={CosmeGainStyles.Inner} data-classname="CosmeGain-Inner">
        <StaticImage
          src="../../../images/cosme/gain_headline_heart-face_mobile.png"
          alt=""
          aria-hidden="true"
          width={82}
          height={162}
          className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeGainStyles.Decoration({ position: 'left' }))}
          data-classname="CosmeGain-Decoration"
        />
        <StaticImage
          src="../../../images/cosme/gain_headline_happy-face_mobile.png"
          alt=""
          aria-hidden="true"
          width={39}
          height={155}
          className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeGainStyles.Decoration({ position: 'right' }))}
          data-classname="CosmeGain-Decoration"
        />
        <StaticImage
          src="../../../images/cosme/gain_headline_heart-face_desktop.png"
          alt=""
          aria-hidden="true"
          width={148}
          height={191}
          className={classNames(SiteWideStyles.DisplayDesktopOnly, CosmeGainStyles.Decoration({ position: 'left' }))}
          data-classname="CosmeGain-Decoration"
        />
        <StaticImage
          src="../../../images/cosme/gain_headline_happy-face_desktop.png"
          alt=""
          aria-hidden="true"
          width={154}
          height={198}
          className={classNames(SiteWideStyles.DisplayDesktopOnly, CosmeGainStyles.Decoration({ position: 'right' }))}
          data-classname="CosmeGain-Decoration"
        />
        <div className={CosmeGainStyles.HeadlineOuter} data-classname="CosmeGain-HeadlineOuter">
          <div className={CosmeGainStyles.EyebrowOuter} data-classname="CosmeGain-EyebrowOuter">
            <p className={classNames(CosmeGainStyles.Eyebrow, 'first')} data-classname="CosmeGain-Eyebrow">
              サイト改善がどんどん進む!
            </p>
            <p className={classNames(CosmeGainStyles.Eyebrow, 'second')} data-classname="CosmeGain-Eyebrow">
              アイデアも湧き出る!
            </p>
          </div>
          <h2 className={CosmeGainStyles.Headline} data-classname="CosmeGain-Headline">
            KARTE Blocksで始める、
            <br />
            <span>D2Cブランド</span>の<br className={SiteWideStyles.DisplayMobileOnly} />
            <span>新しいサイト改善</span>
          </h2>
          <p className={CosmeGainStyles.Lead} data-classname="CosmeGain-Lead">
            タグを導入するだけで、今のサイトやLPを即座に改修・A/Bテスト・パーソナライズ。
            <br className={SiteWideStyles.DisplayDesktopOnly} />
            ブロック単位でノーコードで直感的に編集でき、
            <span>改修後の効果検証までワンストップ</span>で。
          </p>
          <CosmeDownloadButton className={CosmeGainStyles.ButtonTop} data-classname="CosmeGain-ButtonTop" />
        </div>
        <div className={classNames(CosmeGainStyles.Catalog_HeadlineOuter, 'number01')} data-classname="CosmeGain-Catalog_HeadlineOuter">
          <h3 className={CosmeGainStyles.Catalog_Headline} data-classname="CosmeGain-Catalog_Headline">
            広告の流入経路に合わせた、
            <br className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeGainStyles.Catalog_Br)} />
            <span>効果的なLP</span>を検証
          </h3>
        </div>
        <article className={CosmeGainStyles.Catalog_Item({ item: 'item01' })} data-classname="CosmeGain-Catalog_Item">
          <StaticImage
            src="../../../images/cosme/gain_1.png"
            alt="「広告の流入経路に合わせた、効果的なLPを検証」のイメージイラスト"
            width={407}
            height={365}
            className={classNames(CosmeGainStyles.Catalog_Image({ item: 'item01' }), 'image01')}
            data-classname="CosmeGain-Catalog_Image"
          />
          <div>
            <div className={CosmeGainStyles.Catalog_Summary({ item: 'item01' })} data-classname="CosmeGain-Catalog_Summary">
              <div className={CosmeGainStyles.Summary_Inner({ color: 'black' })} data-classname="CosmeGain-Summary_Inner">
                <span className={CosmeGainStyles.Summary_Tag({ color: 'black' })} data-classname="CosmeGain-Summary_Tag">
                  今は...
                </span>
                <p className={CosmeGainStyles.Summary_Content({ color: 'black' })} data-classname="CosmeGain-Summary_Content">
                  集客チャネル・訴求ポイントは色々あるけど、流入後のLPはワンパターン。広告のクリエイティブも頻繁に変えるからLP運用が追いつかない...。
                </p>
                <StaticImage
                  src="../../../images/cosme/gain_sad-face.png"
                  alt=""
                  aria-hidden="true"
                  width={31}
                  height={31}
                  className={classNames(CosmeGainStyles.Summary_Icon, 'sad-right')}
                  data-classname="CosmeGain-Summary_Icon"
                />
              </div>
            </div>
            <div className={CosmeGainStyles.Catalog_Summary({ item: 'item01' })} data-classname="CosmeGain-Catalog_Summary">
              <div className={CosmeGainStyles.Summary_Inner({ color: 'accent' })} data-classname="CosmeGain-Summary_Inner">
                <span className={CosmeGainStyles.Summary_Tag({ color: 'accent' })} data-classname="CosmeGain-Summary_Tag">
                  Blocksなら!
                </span>
                <div className={CosmeGainStyles.Summary_Content({ color: 'accent' })} data-classname="CosmeGain-Summary_Content">
                  <p>
                    流入経路やキャンペーンに合わせて<span>ファーストビューを自在に切り替え</span>。 LPの制作<span>コストを最小限</span>に、
                    <span>一貫した訴求</span>で成果を高める。
                  </p>
                </div>
                <StaticImage
                  src="../../../images/cosme/gain_happy-face.png"
                  alt=""
                  aria-hidden="true"
                  width={38}
                  height={38}
                  className={classNames(CosmeGainStyles.Summary_Icon, 'happy-left')}
                  data-classname="CosmeGain-Summary_Icon"
                />
              </div>
            </div>
          </div>
        </article>
        <div className={classNames(CosmeGainStyles.Catalog_HeadlineOuter, 'number02')} data-classname="CosmeGain-Catalog_HeadlineOuter">
          <h3 className={CosmeGainStyles.Catalog_Headline} data-classname="CosmeGain-Catalog_Headline">
            顧客行動に合わせて
            <br className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeGainStyles.Catalog_Br)} />
            パーソナライズ。
            <br />
            <span>的確な訴求</span>でLTVを向上
          </h3>
        </div>
        <article className={CosmeGainStyles.Catalog_Item({ item: 'item02' })} data-classname="CosmeGain-Catalog_Item">
          <StaticImage
            src="../../../images/cosme/gain_2.png"
            alt="「顧客行動に合わせてパーソナライズ。的確な訴求でLTVを向上」のイメージイラスト"
            width={466}
            height={376}
            className={CosmeGainStyles.Catalog_Image({ item: 'item02' })}
            data-classname="CosmeGain-Catalog_Image"
          />
          <div>
            <div className={CosmeGainStyles.Catalog_Summary({ item: 'item02' })} data-classname="CosmeGain-Catalog_Summary">
              <div className={CosmeGainStyles.Summary_Inner({ color: 'black' })} data-classname="CosmeGain-Summary_Inner">
                <span className={CosmeGainStyles.Summary_Tag({ color: 'black' })} data-classname="CosmeGain-Summary_Tag">
                  今は...
                </span>
                <p className={CosmeGainStyles.Summary_Content({ color: 'black' })} data-classname="CosmeGain-Summary_Content">
                  もっと顧客に合わせたオファーで、購入を後押ししたい。限られたスペースで全部を伝えるのは無理...。
                </p>
                <StaticImage
                  src="../../../images/cosme/gain_sad-face_left.png"
                  alt=""
                  aria-hidden="true"
                  width={31}
                  height={31}
                  className={classNames(CosmeGainStyles.Summary_Icon, 'sad-left')}
                  data-classname="CosmeGain-Summary_Icon"
                />
              </div>
            </div>
            <div className={CosmeGainStyles.Catalog_Summary({ item: 'item02' })} data-classname="CosmeGain-Catalog_Summary">
              <div className={CosmeGainStyles.Summary_Inner({ color: 'accent' })} data-classname="CosmeGain-Summary_Inner">
                <span
                  className={CosmeGainStyles.Summary_Tag({ color: 'accent', position: 'right' })}
                  data-classname="CosmeGain-Summary_Tag">
                  Blocksなら!
                </span>
                <div className={CosmeGainStyles.Summary_Content({ color: 'accent' })} data-classname="CosmeGain-Summary_Content">
                  <p>
                    あらゆるシーンで<span>パーソナライズされた訴求を自在に</span>
                    。トライアル、定期引き上げからクロスセルまで、<span>自然にご提案</span>
                  </p>
                </div>
                <StaticImage
                  src="../../../images/cosme/gain_heart-face.png"
                  alt=""
                  aria-hidden="true"
                  width={38}
                  height={38}
                  className={classNames(CosmeGainStyles.Summary_Icon, 'heart-right')}
                  data-classname="CosmeGain-Summary_Icon"
                />
              </div>
            </div>
          </div>
        </article>
        <div className={classNames(CosmeGainStyles.Catalog_HeadlineOuter, 'number03')} data-classname="CosmeGain-Catalog_HeadlineOuter">
          <h3 className={CosmeGainStyles.Catalog_Headline} data-classname="CosmeGain-Catalog_Headline">
            ユーザーデータと
            <br className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeGainStyles.Catalog_Br)} />
            ライブ動画で<span>直感分析</span>。
            <br />
            <span>ボトルネックを特定</span>して
            <br className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeGainStyles.Catalog_Br)} />
            次の改善へ
          </h3>
        </div>
        <article className={CosmeGainStyles.Catalog_Item({ item: 'item03' })} data-classname="CosmeGain-Catalog_Item">
          <StaticImage
            src="../../../images/cosme/gain_3.png"
            alt="「ユーザーデータとライブ動画で直感分析。ボトルネックを特定して次の改善へ」のイメージイラスト"
            width={365}
            height={359}
            className={CosmeGainStyles.Catalog_Image({ item: 'item03' })}
            data-classname="CosmeGain-Catalog_Image"
          />
          <div>
            <div className={CosmeGainStyles.Catalog_Summary({ item: 'item03' })} data-classname="CosmeGain-Catalog_Summary">
              <div className={CosmeGainStyles.Summary_Inner({ color: 'black' })} data-classname="CosmeGain-Summary_Inner">
                <span className={CosmeGainStyles.Summary_Tag({ color: 'black' })} data-classname="CosmeGain-Summary_Tag">
                  今は...
                </span>
                <p className={CosmeGainStyles.Summary_Content({ color: 'black' })} data-classname="CosmeGain-Summary_Content">
                  サイト改修してみたけど、特段変化もなく...。
                  <br className={SiteWideStyles.DisplayDesktopOnly} />
                  次の打ち手も見つからない。
                </p>
                <StaticImage
                  src="../../../images/cosme/gain_sad-face.png"
                  alt=""
                  aria-hidden="true"
                  width={31}
                  height={31}
                  className={classNames(CosmeGainStyles.Summary_Icon, 'sad-right')}
                  data-classname="CosmeGain-Summary_Icon"
                />
              </div>
            </div>
            <div className={CosmeGainStyles.Catalog_Summary({ item: 'item03' })} data-classname="CosmeGain-Catalog_Summary">
              <div className={CosmeGainStyles.Summary_Inner({ color: 'accent' })} data-classname="CosmeGain-Summary_Inner">
                <span className={CosmeGainStyles.Summary_Tag({ color: 'accent' })} data-classname="CosmeGain-Summary_Tag">
                  Blocksなら!
                </span>
                <div className={CosmeGainStyles.Summary_Content({ color: 'accent' })} data-classname="CosmeGain-Summary_Content">
                  <p>
                    同じ改修でも、流入キャンペーンごとに<span>異なるユーザー反応</span>が。<span>サイト訪問時の再現動画で確認</span>
                    して、原因を発見！
                  </p>
                </div>
                <StaticImage
                  src="../../../images/cosme/gain_happy-face.png"
                  alt=""
                  aria-hidden="true"
                  width={38}
                  height={38}
                  className={classNames(CosmeGainStyles.Summary_Icon, 'happy-left')}
                  data-classname="CosmeGain-Summary_Icon"
                />
              </div>
            </div>
          </div>
        </article>
        <CosmeDownloadButton className={CosmeGainStyles.ButtonBottom} data-classname="CosmeGain-ButtonBottom" />
      </div>
    </section>
  )
}

export default CosmeGain
