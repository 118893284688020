import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import * as CosmeDownloadButtonStyles from '../../../styles/pages/cosme/DownloadButton.css'
import classNames from 'classnames'

type CosmeDownloadButtonProps = {
  className?: string
  dataClassname?: string
}

const CosmeDownloadButton = ({ className, dataClassname }: CosmeDownloadButtonProps) => {
  return (
    <Link to="#download" className={classNames(CosmeDownloadButtonStyles.Button, className)} data-classname={dataClassname}>
      資料ダウンロード
      <FontAwesomeIcon
        icon={faArrowRight}
        width={18}
        height={16}
        className={CosmeDownloadButtonStyles.Button_Icon}
        data-classname="CosmeDownloadButton-Button_Icon"
      />
    </Link>
  )
}

export default CosmeDownloadButton
