import React from 'react'
import backgroundDesktopLeft from '../../../images/cosme/price_background_desktop_left.png'
import backgroundDesktopRight from '../../../images/cosme/price_background_desktop_right.png'
import backgroundMobileLeft from '../../../images/cosme/price_background_mobile_left.png'
import backgroundMobileRight from '../../../images/cosme/price_background_mobile_right.png'
import * as CosmePriceStyles from '../../../styles/pages/cosme/Price.css'
import CosmeDownloadButton from './DownloadButton'

const CosmePrice = () => {
  return (
    <section className={CosmePriceStyles.Container} data-classname="CosmePrice-Container">
      <div className={CosmePriceStyles.Inner} data-classname="CosmePrice-Inner">
        <h2 className={CosmePriceStyles.Headline} data-classname="CosmePrice-Headline">
          KARTE Blocks{' '}
          <span className={CosmePriceStyles.Green} data-classname="CosmePrice-Green">
            料金プラン
          </span>
        </h2>
        <div className={CosmePriceStyles.Listing} data-classname="CosmePrice-Listing">
          <div className={CosmePriceStyles.Listing_Item} data-classname="CosmePrice-Listing_Item">
            <h3 className={CosmePriceStyles.Listing_Headline} data-classname="CosmePrice-Listing_Headline">
              初期費用
            </h3>
            <div className={CosmePriceStyles.Listing_Price} data-classname="CosmePrice-Listing_Price">
              10
              <span className={CosmePriceStyles.Listing_Unit} data-classname="CosmePrice-Listing_Unit">
                万円
              </span>
            </div>
            <p className={CosmePriceStyles.Listing_Summary} data-classname="CosmePrice-Listing_Summary">
              導入プログラム・学習コンテンツのご提供
              <br />
              チャット窓口でもサポートを実施
            </p>
          </div>
          <div className={CosmePriceStyles.Listing_Item} data-classname="CosmePrice-Listing_Item">
            <h3 className={CosmePriceStyles.Listing_Headline} data-classname="CosmePrice-Listing_Headline">
              月額固定
            </h3>
            <div className={CosmePriceStyles.Listing_Price} data-classname="CosmePrice-Listing_Price">
              12.5
              <span className={CosmePriceStyles.Listing_Unit} data-classname="CosmePrice-Listing_Unit">
                万円〜
              </span>
            </div>
            <p className={CosmePriceStyles.Listing_Summary} data-classname="CosmePrice-Listing_Summary">
              従量課金式による料金変動なし
            </p>
          </div>

          <div className={CosmePriceStyles.Card} data-classname="CosmePrice-Card">
            <h3 className={CosmePriceStyles.Card_Headline} data-classname="CosmePrice-Card_Headline">
              Fair Policy
            </h3>
            <p className={CosmePriceStyles.Card_Summary} data-classname="CosmePrice-Card_Summary">
              実際に運用するページのPV数をカウント。
              <br />
              使っていないページ分の費用は
              <br className={CosmePriceStyles.Summary_Br} />
              抑えられます。
            </p>
          </div>
          <CosmeDownloadButton className={CosmePriceStyles.Listing_Button} data-classname="CosmePrice-Listing_Button" />
        </div>
        <picture>
          <source
            media="(min-width: 768px)"
            width="229"
            height="621"
            srcSet={backgroundDesktopLeft}
            className={CosmePriceStyles.Background({ position: 'left' })}
            data-classname="CosmePrice-Background"
          />
          <img
            src={backgroundMobileLeft}
            width="83"
            height="282"
            alt=""
            decoding="async"
            className={CosmePriceStyles.Background({ position: 'left' })}
            data-classname="CosmePrice-Background"
          />
        </picture>
        <picture>
          <source
            media="(min-width: 768px)"
            width="203"
            height="616"
            srcSet={backgroundDesktopRight}
            className={CosmePriceStyles.Background({ position: 'right' })}
            data-classname="CosmePrice-Background"
          />
          <img
            src={backgroundMobileRight}
            width="76"
            height="438"
            alt=""
            decoding="async"
            className={CosmePriceStyles.Background({ position: 'right' })}
            data-classname="CosmePrice-Background"
          />
        </picture>
      </div>
    </section>
  )
}

export default CosmePrice
