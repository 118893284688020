import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as CosmeHeroStyles from '../../../styles/pages/cosme/Hero.css'
import * as CosmeCarouselStyles from '../../../styles/pages/cosme/Carousel.css'
import CosmeDownloadButton from '../../../components/pages/cosme/DownloadButton'

const CosmeHero = () => {
  return (
    <section className={CosmeHeroStyles.Container} data-classname="CosmeHero-Container">
      <div className={CosmeHeroStyles.Inner} data-classname="CosmeHero-Inner">
        <div className={CosmeHeroStyles.EyebrowOuter} data-classname="CosmeHero-EyebrowOuter">
          <p className={CosmeHeroStyles.Eyebrow} data-classname="CosmeHero-Eyebrow">
            D2Cブランドのサイト改善なら<span>KARTE Blocks</span>
          </p>
        </div>
        <h1 className={CosmeHeroStyles.Headline} data-classname="CosmeHero-Headline">
          自在に
          <br className={CosmeHeroStyles.Headline_Br} />
          <span>改修・パーソナライズ</span>
          <br />
          持続的に
          <br className={CosmeHeroStyles.Headline_Br} />
          <span>サイト成果を高める</span>
        </h1>
        <p className={CosmeHeroStyles.Lead} data-classname="CosmeHero-Lead">
          ユーザーを魅了するブランド体験を思いのままに。
        </p>
        <StaticImage
          src="../../../images/cosme/key-visual.png"
          alt=""
          width={1074}
          height={742}
          className={CosmeHeroStyles.Image}
          data-classname="CosmeHero-Image"
        />
        <CosmeDownloadButton className={CosmeHeroStyles.Button} data-classname="CosmeHero-Button" />
        <DisplayDesktopOnlyCatalog />
        <DisplayMobileOnlyCatalog />
      </div>
    </section>
  )
}

const ctalog_data = [
  {
    summary: (
      <>
        <span>A/Bテストの効果が明確</span>になり施策の判断や優先順位が付けやすくなった。<span>思わぬ気づき</span>から、
        <span>新たな施策の種</span>につながっている
      </>
    ),
    logo: (
      <StaticImage
        src="../../../images/cosme/lacasta_logo.png"
        alt="La CASTAのロゴ"
        width={154}
        height={46}
        className={classNames(CosmeHeroStyles.Logo, 'lacasta')}
        data-classname="CosmeHero-Logo-lacasta"
      />
    ),
    icon: [
      <StaticImage
        src="../../../images/cosme/lacasta_icon1.png"
        alt=""
        width={60}
        height={60}
        className={CosmeHeroStyles.Icon}
        data-classname="CosmeHero-Icon"
      />,
      <StaticImage
        src="../../../images/cosme/lacasta_icon2.png"
        alt=""
        width={60}
        height={60}
        className={CosmeHeroStyles.Icon}
        data-classname="CosmeHero-Icon"
      />,
    ],
    url: '/blog/userstory_alpenrose/',
  },
  {
    summary: (
      <>
        エンジニアの力を借りずに<span>ノーコードでサイトやLPを編集</span>し、セグメントを柔軟に設定して顧客に合わせて
        <span>コンテンツを最適化</span>できる
      </>
    ),
    logo: (
      <StaticImage
        src="../../../images/cosme/tricot_logo.png"
        alt="tricotのロゴ"
        width={92}
        height={35}
        className={classNames(CosmeHeroStyles.Logo, 'tricot')}
        data-classname="CosmeHero-Logo-tricot"
      />
    ),
    icon: [
      <StaticImage
        src="../../../images/cosme/tricot_icon1.png"
        alt=""
        width={60}
        height={60}
        className={CosmeHeroStyles.Icon}
        data-classname="CosmeHero-Icon"
      />,
    ],
    url: '/blog/userstory_fujimi/',
  },
  {
    summary: (
      <>
        お客様に合わせた
        <span>個別のオファーをECサイトのUI/UXで実現</span>できるようになることが導入の決め手。「まさに<span>理想のプロダクト</span>」
      </>
    ),
    logo: (
      <StaticImage
        src="../../../images/cosme/macchia-lavel_logo.png"
        alt="マキアイレイベルのロゴ"
        width={154}
        height={49}
        className={classNames(CosmeHeroStyles.Logo, 'macchia-lavel')}
        data-classname="CosmeHero-Logo-macchia-lavel"
      />
    ),
    icon: [
      <StaticImage
        src="../../../images/cosme/macchia-lavel_icon1.png"
        alt=""
        width={60}
        height={60}
        className={CosmeHeroStyles.Icon}
        data-classname="CosmeHero-Icon"
      />,
      <StaticImage
        src="../../../images/cosme/macchia-lavel_icon2.png"
        alt=""
        width={60}
        height={60}
        className={CosmeHeroStyles.Icon}
        data-classname="CosmeHero-Icon"
      />,
    ],
    url: '/blog/userstory_jimos_macchia-lable/',
  },
]

const DisplayDesktopOnlyCatalog = () => (
  <div className={classNames(SiteWideStyles.DisplayDesktopOnly, CosmeHeroStyles.Catalog)} data-classname="CosmeHero-Catalog">
    {ctalog_data.map((item, index) => (
      <article className={CosmeHeroStyles.Catalog_Item} key={`CosmeHero-Catalog_${index}`} data-classname="CosmeHero-Catalog_Item">
        <div className={CosmeHeroStyles.Balloon} data-classname="CosmeHero-Balloon">
          <p className={CosmeHeroStyles.Summary} data-classname="CosmeHero-Summary">
            {item.summary}
          </p>
          {item.logo}
        </div>
        <div className={CosmeHeroStyles.Content} data-classname="CosmeHero-Content">
          <div>
            {item.icon.map((image) => (
              <>{image}</>
            ))}
          </div>
          <a href={item.url} target="_blank" rel="noopener" className={CosmeHeroStyles.LinkText} data-classname="CosmeHero-LinkText">
            記事を読む
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              width={28}
              height={28}
              className={CosmeHeroStyles.LinkText_Icon}
              data-classname="CosmeHero-LinkText_Icon"
            />
          </a>
        </div>
      </article>
    ))}
  </div>
)

const DisplayMobileOnlyCatalog = () => (
  <div className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeHeroStyles.Catalog)} data-classname="CosmeHero-Catalog">
    {ctalog_data.map((item, index) => (
      <article className={CosmeHeroStyles.Catalog_Item} key={`CosmeHero-Catalog_${index}`} data-classname="CosmeHero-Catalog_Item">
        <div className={CosmeHeroStyles.Balloon} data-classname="CosmeHero-Balloon">
          <p className={CosmeHeroStyles.Summary} data-classname="CosmeHero-Summary">
            {item.summary}
          </p>
          {item.logo}
        </div>
        <div className={CosmeHeroStyles.Content} data-classname="CosmeHero-Content">
          <div>
            {item.icon.map((image) => (
              <>{image}</>
            ))}
          </div>
          <a href={item.url} target="_blank" rel="noopener" className={CosmeHeroStyles.LinkText} data-classname="CosmeHero-LinkText">
            記事を読む
            <FontAwesomeIcon
              icon={faCircleArrowRight}
              width={28}
              height={28}
              className={CosmeHeroStyles.LinkText_Icon}
              data-classname="CosmeHero-LinkText_Icon"
            />
          </a>
        </div>
      </article>
    ))}
    <div className={CosmeCarouselStyles.Navigation} data-classname="CosmeHero-Navigation">
      <button className={CosmeCarouselStyles.Navigation_Button} data-classname="CosmeHero-Navigation_Button">
        <FontAwesomeIcon icon={faAngleLeft} width={7} height={7} />
      </button>
      <button className={CosmeCarouselStyles.Navigation_Button} data-classname="CosmeHero-Navigation_Button">
        <FontAwesomeIcon icon={faAngleRight} width={7} height={7} />
      </button>
    </div>
    <div className={CosmeCarouselStyles.Indigator} data-classname="CosmeHero-Indigator">
      <span className={CosmeCarouselStyles.Bar()} data-classname="CosmeHero-Bar"></span>
      <span className={CosmeCarouselStyles.Bar({ active: true })} data-classname="CosmeHero-Bar"></span>
      <span className={CosmeCarouselStyles.Bar()} data-classname="CosmeHero-Bar"></span>
    </div>
  </div>
)

export default CosmeHero
