import React from 'react'
import { PageContextPropsType } from '../../../i18n/i18n'
import SEO from '../../components/SEO'
import LayoutCosme from '../../components/LayoutCosme'
import CosmeHero from '../../components/pages/cosme/Hero'
import CosmeProblem from '../../components/pages/cosme/Problem'
import CosmeGain from '../../components/pages/cosme/Gain'
import CosmeFeature from '../../components/pages/cosme/Feature'
import CosmeUsecase from '../../components/pages/cosme/Usecase'
import CosmePrice from '../../components/pages/cosme/Price'
import CosmeFAQ from '../../components/pages/cosme/FAQ'
import CosmeNextAction from '../../components/pages/cosme/NextAction'
import CosmeDownload from '../../components/pages/cosme/Download'
import download from '../../images/cosme/download_image.png'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export default function CosmePage({ pageContext }: PageContextPropsType) {
  return (
    <LayoutCosme pageContext={pageContext}>
      <SEO
        title="コスメ・D2C業界も、思いのままにUI変更｜KARTE Blocks"
        description="広告CPCが高騰。流入後から購入、その先の体験を重視したい。でもサイトの修正には時間がかかる。そんなコスメ・D2Cあるあるにも、KARTE Blocksなら自在に変更・ワンストップで検証。"
        path="/cosme/"
      />
      <main>
        <CosmeHero />
        <CosmeProblem />
        <CosmeGain />
        <CosmeFeature />
        <CosmeUsecase />
        <CosmePrice />
        <CosmeFAQ />
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_CLIENT_SECRET_KEY ?? ''}>
          <CosmeDownload
            data={{
              downloadImage: {
                src: download,
                _id: '',
              },
              downloadText: '本資料では、KARTE Blocksの基本的な機能の紹介から、D2C業界における最新活用事例、お客様の声を記載しております。',
              downloadNumberList: [
                { numberListText: 'サイト運営の実情' },
                { numberListText: 'KARTE Blocksについて' },
                { numberListText: 'KARTE Blocksを活用したD2Cサイト事例' },
                { numberListText: 'お客様の声、導入後の変化' },
                { numberListText: 'プラン・サポートについて' },
              ],
              downloadFormUrl: 'https://deal.karte.io/l/819613/2023-11-14/bv6dm',
            }}
            slug="cosme"
          />
        </GoogleReCaptchaProvider>
        <CosmeNextAction />
      </main>
    </LayoutCosme>
  )
}
