import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import CosmeDownloadButton from './DownloadButton'
import classNames from 'classnames'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as CosmeUsecaseStyles from '../../../styles/pages/cosme/Usecase.css'
import * as CosmeCarouselStyles from '../../../styles/pages/cosme/Carousel.css'

const CosmeUsecase = () => {
  return (
    <section className={CosmeUsecaseStyles.Container} data-classname="CosmeUsecase-Container">
      <div className={CosmeUsecaseStyles.Inner} data-classname="CosmeUsecase-Inner">
        <div className={CosmeUsecaseStyles.HeadlineOuter} data-classname="CosmeUsecase-HeadlineOuter">
          <StaticImage
            src="../../../images/cosme/usecase_decoration_desktop_left.png"
            alt=""
            width={132}
            height={42}
            className={classNames(SiteWideStyles.DisplayDesktopOnly, CosmeUsecaseStyles.Decoration({ position: 'left' }))}
            data-classname="CosmeUsecase-Decoration"
          />
          <StaticImage
            src="../../../images/cosme/usecase_decoration_mobile_left.png"
            alt=""
            width={68}
            height={29}
            className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeUsecaseStyles.Decoration({ position: 'left' }))}
            data-classname="CosmeUsecase-Decoration"
          />
          <h2 className={CosmeUsecaseStyles.Headline} data-classname="CosmeUsecase-Headline">
            活用事例
          </h2>
          <StaticImage
            src="../../../images/cosme/usecase_decoration_desktop_right.png"
            alt=""
            width={135}
            height={42}
            className={classNames(SiteWideStyles.DisplayDesktopOnly, CosmeUsecaseStyles.Decoration({ position: 'right' }))}
            data-classname="CosmeUsecase-Decoration"
          />
          <StaticImage
            src="../../../images/cosme/usecase_decoration_mobile_right.png"
            alt=""
            width={64}
            height={30}
            className={classNames(SiteWideStyles.DisplayMobileOnly, CosmeUsecaseStyles.Decoration({ position: 'right' }))}
            data-classname="CosmeUsecase-Decoration"
          />
        </div>
        <p className={CosmeUsecaseStyles.Lead} data-classname="CosmeUsecase-Lead">
          D2Cブランドサイトにおける
          <br className={SiteWideStyles.DisplayMobileOnly} />
          KARTE Blocksの導入続々！
        </p>
        <div className={CosmeUsecaseStyles.Catalog} data-classname="CosmeUsecase-Catalog">
          <article className={CosmeUsecaseStyles.Card()} data-classname="CosmeUsecase-Card">
            <img
              src="/assets/images/cosme/usecase_image01.png"
              alt=""
              width={399}
              height={231}
              className={CosmeUsecaseStyles.Card_Image}
              data-classname="CosmeUsecase-Card_Image"
            />
            <div className={CosmeUsecaseStyles.Card_Content} data-classname="CosmeUsecase-Card_Content">
              <p className={CosmeUsecaseStyles.Card_Summary} data-classname="CosmeUsecase-Card_Summary">
                特定のユーザー層向け広告から流入時、<span>LPのファーストビューや構成をパーソナライズ</span>
                。開発工数をかけずマーケターで高速検証。
              </p>
              <div className={CosmeUsecaseStyles.Card_Logo} data-classname="CosmeUsecase-Card_Logo">
                <img src="/assets/images/cosme/tricot_logo.png" alt="" width={92} height={36} />
              </div>
            </div>
          </article>
          <article className={CosmeUsecaseStyles.Card({ active: true })} data-classname="CosmeUsecase-Card">
            <img
              src="/assets/images/cosme/usecase_image01.png"
              alt=""
              width={399}
              height={231}
              className={CosmeUsecaseStyles.Card_Image}
              data-classname="CosmeUsecase-Card_Image"
            />
            <div className={CosmeUsecaseStyles.Card_Content} data-classname="CosmeUsecase-Card_Content">
              <p className={CosmeUsecaseStyles.Card_Summary} data-classname="CosmeUsecase-Card_Summary">
                特定のユーザー層向け広告から流入時、<span>LPのファーストビューや構成をパーソナライズ</span>
                。開発工数をかけずマーケターで高速検証。
              </p>
              <div className={CosmeUsecaseStyles.Card_Logo} data-classname="CosmeUsecase-Card_Logo">
                <img src="/assets/images/cosme/tricot_logo.png" alt="" width={92} height={36} />
              </div>
            </div>
          </article>
          <article className={CosmeUsecaseStyles.Card()} data-classname="CosmeUsecase-Card">
            <img
              src="/assets/images/cosme/usecase_image01.png"
              alt=""
              width={399}
              height={231}
              className={CosmeUsecaseStyles.Card_Image}
              data-classname="CosmeUsecase-Card_Image"
            />
            <div className={CosmeUsecaseStyles.Card_Content} data-classname="CosmeUsecase-Card_Content">
              <p className={CosmeUsecaseStyles.Card_Summary} data-classname="CosmeUsecase-Card_Summary">
                特定のユーザー層向け広告から流入時、<span>LPのファーストビューや構成をパーソナライズ</span>
                。開発工数をかけずマーケターで高速検証。
              </p>
              <div className={CosmeUsecaseStyles.Card_Logo} data-classname="CosmeUsecase-Card_Logo">
                <img src="/assets/images/cosme/tricot_logo.png" alt="" width={92} height={36} />
              </div>
            </div>
          </article>
          <div
            className={classNames(CosmeCarouselStyles.Navigation, CosmeUsecaseStyles.Navigation)}
            data-classname="CosmeUsecase-Navigation">
            <button className={CosmeCarouselStyles.Navigation_Button} data-classname="CosmeUsecase-Navigation_Button">
              <FontAwesomeIcon
                icon={faAngleLeft}
                width={7}
                height={7}
                className={CosmeCarouselStyles.Navigation_Icon}
                data-classname="CosmeUsecase-Navigation_Icon"
              />
            </button>
            <button className={CosmeCarouselStyles.Navigation_Button} data-classname="CosmeUsecase-Navigation_Button">
              <FontAwesomeIcon
                icon={faAngleRight}
                width={7}
                height={7}
                className={CosmeCarouselStyles.Navigation_Icon}
                data-classname="CosmeUsecase-Navigation_Icon"
              />
            </button>
          </div>
          <div className={CosmeCarouselStyles.Indigator} data-classname="CosmeUsecase-Indigator">
            <span className={CosmeCarouselStyles.Bar()} data-classname="CosmeUsecase-Bar"></span>
            <span className={CosmeCarouselStyles.Bar({ active: true })} data-classname="CosmeUsecase-Bar"></span>
            <span className={CosmeCarouselStyles.Bar()} data-classname="CosmeUsecase-Bar"></span>
          </div>
        </div>
        <CosmeDownloadButton className={CosmeUsecaseStyles.Button} dataClassname="CosmeUsecase-Button" />
      </div>
    </section>
  )
}

export default CosmeUsecase
