import React, { useState, useRef, ReactElement } from 'react'
import { motion } from 'framer-motion'
import * as CosmeFAQStyles from '../../../styles/pages/cosme/FAQ.css'

const FAQItem = ({ question, answer }: { question: string; answer: string | ReactElement }) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const toggleOpen = () => setIsOpen(!isOpen)

  const variants = {
    open: {
      rotate: 90,
    },
    closed: {
      rotate: 0,
    },
  }

  return (
    <div className={CosmeFAQStyles.Listing_Item} data-classname="CosmeFAQ-Listing_Item">
      <dt onClick={toggleOpen} className={CosmeFAQStyles.Listing_Headline} data-classname="CosmeFAQ-Listing_Headline">
        {question}
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          stroke="#12A0A0"
          strokeWidth="2.5"
          strokeLinecap="round"
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          className={CosmeFAQStyles.Listing_Icon}
          data-classname="CosmeFAQ-Listing_Icon">
          <path d="M2 7H12" />
          <motion.path d="M7 2V12" variants={variants} />
        </motion.svg>
      </dt>
      <motion.dd
        initial={{ height: 0, marginTop: 0, overflow: 'hidden' }}
        animate={{ height: isOpen ? contentRef.current?.scrollHeight : 0 }}
        exit={{ height: 0, marginTop: 0 }}>
        <motion.div
          ref={contentRef}
          animate={{ borderTopColor: isOpen ? '#DFDFDF' : 'transparent' }}
          className={CosmeFAQStyles.Listing_Summary}
          data-classname="CosmeFAQ-Listing_Summary">
          {answer}
        </motion.div>
      </motion.dd>
    </div>
  )
}

const CosmeFAQ = () => {
  const faqData = [
    {
      question: 'カートシステムを利用している場合でも使用できますか？',
      answer: 'はい。KARTE Blocksの指定のscriptタグをサイトやLPに設置することで利用可能です。',
    },
    {
      question: '本サイトに加え、複数あるLPでもまとめて利用したいのですが可能ですか？',
      answer: (
        <>
          はい。同一ドメインであれば一つの管理画面で利用可能です。
          <br />
          ご利用料金は、導入範囲における月間のページビュー数の合計を元に決定致します。
          <br />
          詳細はお問い合わせください。
        </>
      ),
    },
    {
      question: '変更した箇所の表示が遅れたり、ページの読み込み速度が大きく悪化することはありませんか？',
      answer:
        'KARTE Blocksはページ読み込みに合わせて同期的にブロックの変更を行う仕様であり、変更箇所の表示遅延は発生しませんのでご安心ください。また、scriptタグの軽量化をはじめとして、ページ読み込み速度への影響を大きく緩和するための設計を施しています。詳細はお問い合わせください。',
    },
    {
      question: '導入時、および利用時のサポートはありますか？',
      answer:
        'はい。導入支援プログラムとして自習型コンテンツに加え、プロジェクトを進行するための個別打ち合わせもご用意しております。加えて、平日の10:30 - 17:30の時間帯で、プロダクト専門チームとのチャットサポート窓口も開放しております。',
    },
  ]

  return (
    <section className={CosmeFAQStyles.Container} data-classname="CosmeFAQ-Container">
      <div className={CosmeFAQStyles.Inner} data-classname="CosmeFAQ-Inner">
        <h2 className={CosmeFAQStyles.Headline} data-classname="CosmeFAQ-Headline">
          よくあるご質問
        </h2>
        <dl className={CosmeFAQStyles.Listing} data-classname="CosmeFAQ-Listing">
          {faqData.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </dl>
      </div>
    </section>
  )
}

export default CosmeFAQ
