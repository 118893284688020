import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { IntlProvider } from 'react-intl'
import { PageContextPropsType } from '../../i18n/i18n'
import 'ress'
import '../styles/Global.css'
import Footer from '../components/Footer'
import Headers from './Headers'

type Props = {
  children: React.ReactNode
} & PageContextPropsType

const LayoutCosme: React.VFC<Props> = ({ children, pageContext }: Props) => {
  const language = pageContext.language
  const messages = pageContext.messages
  const location = useLocation()

  useEffect(() => {
    document.dispatchEvent(new Event('karte-blocks-force-activate'))
  }, [location.pathname])

  return (
    <IntlProvider locale={language} messages={messages}>
      <Headers />
      {children}
      <Footer />
    </IntlProvider>
  )
}

export default LayoutCosme
