import React from 'react'
import classNames from 'classnames'
import arrowBlack from '../../../images/cosme/next-action_arrow_black.svg'
import arrowWhite from '../../../images/cosme/next-action_arrow_white.svg'
import background from '../../../images/cosme/next-action_background.svg'
import * as SiteWideStyles from '../../../styles/SiteWide.css'
import * as CosmeNextActionStyles from '../../../styles/pages/cosme/NextAction.css'

const CosmeNextAction = () => {
  return (
    <section className={CosmeNextActionStyles.Container} data-classname="CosmeNextAction-Container">
      <div className={CosmeNextActionStyles.Inner} data-classname="CosmeNextAction-Inner">
        <h2 className={CosmeNextActionStyles.Headline} data-classname="CosmeNextAction-Headline">
          KARTE Blocks
          <br className={SiteWideStyles.DisplayMobileOnly} /> 導入のご相談
        </h2>
        <div className={CosmeNextActionStyles.Listing} data-classname="CosmeNextAction-Listing">
          <div className={CosmeNextActionStyles.Listing_Item} data-classname="CosmeNextAction-Listing_Item">
            <span className={CosmeNextActionStyles.Listing_Slogan} data-classname="CosmeNextAction-Listing_Slogan">
              お問い合わせはこちら
            </span>
            <a
              href="https://karte.io/enterprise/"
              target="_blank"
              className={CosmeNextActionStyles.Listing_Button}
              data-classname="CosmeNextAction-Listing_Button">
              <span className={CosmeNextActionStyles.Listing_Button_Text} data-classname="CosmeNextAction-Button_Text">
                フォームから送信
              </span>
              <img
                src={arrowWhite}
                width="14"
                height="11"
                alt=""
                className={CosmeNextActionStyles.Listing_Icon}
                data-classname="CosmeNextAction-Listing_Icon"
              />
              <img
                src={arrowBlack}
                width="14"
                height="11"
                alt=""
                className={classNames(CosmeNextActionStyles.Listing_Icon, 'black')}
                data-classname="CosmeNextAction-Listing_Icon"
              />
            </a>
          </div>
          <div className={CosmeNextActionStyles.Listing_Item} data-classname="CosmeNextAction-Listing_Item">
            <span className={CosmeNextActionStyles.Listing_Slogan} data-classname="CosmeNextAction-Listing_Slogan">
              オンライン商談はこちら
            </span>
            <a
              href="https://plaid.eeasy.jp/karteblocks_meeting?1675930822529"
              target="_blank"
              className={CosmeNextActionStyles.Listing_Button}
              data-classname="CosmeNextAction-Listing_Button">
              <span className={CosmeNextActionStyles.Listing_Button_Text} data-classname="CosmeNextAction-Listing_Button_Text">
                打ち合わせを予約
              </span>
              <img
                src={arrowWhite}
                width="14"
                height="11"
                alt=""
                className={CosmeNextActionStyles.Listing_Icon}
                data-classname="CosmeNextAction-Listing_Icon"
              />
              <img
                src={arrowBlack}
                width="14"
                height="11"
                alt=""
                className={classNames(CosmeNextActionStyles.Listing_Icon, 'black')}
                data-classname="CosmeNextAction-Listing_Icon"
              />
            </a>
          </div>
        </div>
        <img
          src={background}
          width="166"
          height="166"
          alt=""
          className={CosmeNextActionStyles.Background_Left}
          data-classname="CosmeNextAction-Background_Left"
        />
        <img
          src={background}
          width="166"
          height="166"
          alt=""
          className={CosmeNextActionStyles.Background_Right}
          data-classname="CosmeNextAction-Background_Right"
        />
      </div>
    </section>
  )
}

export default CosmeNextAction
